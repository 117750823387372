<template>
  <div ref="container" class="sticky-btn cart">
    <transition duration="200ms" name="fade">
      <PriceBox v-if="visible" theme="2" :productVarietyAvailable="productVarietyAvailable" :product="product" :disabled="disabled"/>
    </transition>
  </div>
</template>

<script>
import PriceBox from "./PriceBox";

export default {
  name: "ProductStickyButton",
  components: {PriceBox},
  data() {
    return {
      visible: false,
      once: false,
      scrollPosition: 0
    }
  },
  props: {
    productVarietyAvailable: String,
    product: Object,
    disabled: Boolean
  },
  mounted() {
    this.scrollPosition = document.getScroll()[1]
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll(e) {
      let top = this.$refs.container.getBoundingClientRect().top
      if (top < 400) {
        this.once = true
        this.visible = true
      } else if (!this.once || top > 500) {
        this.visible = false
      }
      this.scrollPosition = document.getScroll()[1]

    },
    cartToAdd() {
      this.$parent.cartToAdd();
    },
    addToCart() {
      let el = document.getElementById('add-to-cart-btn');
      if (el.disabled) {
        this.$root.warning_notification('این محصول موجود نیست')
      }
      document.getElementById('add-to-cart-btn').click()
    }
  }
}
</script>

<style scoped>
.sticky-btn {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /*background-color: #f1f1f1;*/
  /*box-shadow: 0 0 20px rgba(34, 34, 34, 0.089) , 0 3px 0 rgba(34, 34, 34, 0.089);*/
  font-size: 14px;
  /*width: 250px;*/
  border-radius: 10px;
  left: 0;
  min-width: 310px;
  height: auto;
  padding-top: 1px
}
</style>
