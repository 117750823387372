<template>
  <main class="px-4 p-sm-0 mb-5 in-product-detail">
    <section
      class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 flex-wrap"
    >
      <svg
        class="ml-1 ml-sm-2"
        width="17"
        height="17"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
          stroke="#999"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        fill="currentColor"
        class="ml-1 ml-sm-2 mt-1 bi bi-chevron-double-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
        <path
          fill-rule="evenodd"
          d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
      </svg>
      <router-link to="/" class="fontsize12 text-color-666">خانه</router-link>
      <svg
        class="mx-sm-2 mx-1 mt-1"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <router-link to="/products" class="fontsize11 text-color-a5"
        >محصولات</router-link
      >
      <svg
        v-if="product != null"
        class="mx-sm-2 mx-1 mt-1"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        v-for="(category, index) in hierarchyCategories"
        :key="category.id"
        class="cat-breadcrump"
      >
        <router-link
          :to="'/products/category/' + category.id + '/' + category.slug"
          class="fontsize11 text-color-a5"
        >
          {{ category.name }}
        </router-link>
        <svg
          v-if="index + 1 != hierarchyCategories.length"
          class="mx-sm-2 mx-1 mt-1"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 19L8.5 12L15.5 5"
            stroke="#a5a5a5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span
        v-if="product != null"
        class="fontsize11 text-color-a5 d-none d-sm-block"
        >{{ product.title }}</span
      >
    </section>
    <hr class="w-100 container d-md-block d-none bg-color-fa" />
    <section
      class="product-pric-description p-md-4 p-0 mt-4 bg-white container  border-radius15 box-shaddow20"
    >
      <div class="product-detail-img pl-md-3">
        <div v-if="product != null" class="product-detail-large-img  mb-2">
          <img class="d-block d-lg-none p-img" :src="image" alt="" />
          <!-- <img v-else class="d-block d-lg-none p-img" :src="image" alt=""> -->
          <!-- <zoom-on-hover class="d-none d-lg-block" :img-normal="typeof product.hasVarietyAvailable.image === 'string' ?
          product.hasVarietyAvailable.image : product.hasVarietyAvailable.image.image" :img-zoom="typeof product.hasVarietyAvailable.image === 'string' ?
          product.hasVarietyAvailable.image : product.hasVarietyAvailable.image.image" :scale="2"></zoom-on-hover> -->
          <!-- <zoom-on-hover class="d-none d-lg-block" v-else :img-normal="product.image" :img-zoom="product.image"
            :scale="2"></zoom-on-hover> -->
          <zoom-on-hover
            class="d-none d-lg-block"
            :img-normal="image"
            :img-zoom="image"
            :scale="2"
          ></zoom-on-hover>
          <span class="product-video-favorite">
            <button
              @click="favorite"
              class="border-radius10 bg-white favorite-btn"
            >
              <svg
                v-if="!product.favorite"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8498 2.50071C16.4808 2.50071 17.1108 2.58971 17.7098 2.79071C21.4008 3.99071 22.7308 8.04071 21.6198 11.5807C20.9898 13.3897 19.9598 15.0407 18.6108 16.3897C16.6798 18.2597 14.5608 19.9197 12.2798 21.3497L12.0298 21.5007L11.7698 21.3397C9.4808 19.9197 7.3498 18.2597 5.4008 16.3797C4.0608 15.0307 3.0298 13.3897 2.3898 11.5807C1.2598 8.04071 2.5898 3.99071 6.3208 2.76971C6.6108 2.66971 6.9098 2.59971 7.2098 2.56071H7.3298C7.6108 2.51971 7.8898 2.50071 8.1698 2.50071H8.2798C8.9098 2.51971 9.5198 2.62971 10.1108 2.83071H10.1698C10.2098 2.84971 10.2398 2.87071 10.2598 2.88971C10.4808 2.96071 10.6898 3.04071 10.8898 3.15071L11.2698 3.32071C11.3616 3.36968 11.4647 3.44451 11.5538 3.50918C11.6102 3.55015 11.661 3.58705 11.6998 3.61071C11.7161 3.62034 11.7327 3.63002 11.7494 3.63978C11.8352 3.68983 11.9245 3.74197 11.9998 3.79971C13.1108 2.95071 14.4598 2.49071 15.8498 2.50071ZM18.5098 9.70071C18.9198 9.68971 19.2698 9.36071 19.2998 8.93971V8.82071C19.3298 7.41971 18.4808 6.15071 17.1898 5.66071C16.7798 5.51971 16.3298 5.74071 16.1798 6.16071C16.0398 6.58071 16.2598 7.04071 16.6798 7.18971C17.3208 7.42971 17.7498 8.06071 17.7498 8.75971V8.79071C17.7308 9.01971 17.7998 9.24071 17.9398 9.41071C18.0798 9.58071 18.2898 9.67971 18.5098 9.70071Z"
                  fill="#e1e1e1"
                />
              </svg>
              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8498 2.50071C16.4808 2.50071 17.1108 2.58971 17.7098 2.79071C21.4008 3.99071 22.7308 8.04071 21.6198 11.5807C20.9898 13.3897 19.9598 15.0407 18.6108 16.3897C16.6798 18.2597 14.5608 19.9197 12.2798 21.3497L12.0298 21.5007L11.7698 21.3397C9.4808 19.9197 7.3498 18.2597 5.4008 16.3797C4.0608 15.0307 3.0298 13.3897 2.3898 11.5807C1.2598 8.04071 2.5898 3.99071 6.3208 2.76971C6.6108 2.66971 6.9098 2.59971 7.2098 2.56071H7.3298C7.6108 2.51971 7.8898 2.50071 8.1698 2.50071H8.2798C8.9098 2.51971 9.5198 2.62971 10.1108 2.83071H10.1698C10.2098 2.84971 10.2398 2.87071 10.2598 2.88971C10.4808 2.96071 10.6898 3.04071 10.8898 3.15071L11.2698 3.32071C11.3616 3.36968 11.4647 3.44451 11.5538 3.50918C11.6102 3.55015 11.661 3.58705 11.6998 3.61071C11.7161 3.62034 11.7327 3.63002 11.7494 3.63978C11.8352 3.68983 11.9245 3.74197 11.9998 3.79971C13.1108 2.95071 14.4598 2.49071 15.8498 2.50071ZM18.5098 9.70071C18.9198 9.68971 19.2698 9.36071 19.2998 8.93971V8.82071C19.3298 7.41971 18.4808 6.15071 17.1898 5.66071C16.7798 5.51971 16.3298 5.74071 16.1798 6.16071C16.0398 6.58071 16.2598 7.04071 16.6798 7.18971C17.3208 7.42971 17.7498 8.06071 17.7498 8.75971V8.79071C17.7308 9.01971 17.7998 9.24071 17.9398 9.41071C18.0798 9.58071 18.2898 9.67971 18.5098 9.70071Z"
                  fill="#ff3b3b"
                />
              </svg>
            </button>
            <button
              v-if="product.video != null"
              @click="setVideo"
              class="prodcuct-video bg-white mt-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 172 172"
                style=" fill:#000000;"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,172v-172h172v172z" fill="none"></path>
                  <g>
                    <path
                      d="M169.34609,86c0,-46.02344 -37.32266,-83.34609 -83.34609,-83.34609c-46.02344,0 -83.34609,37.32266 -83.34609,83.34609c0,46.02344 37.32266,83.34609 83.34609,83.34609c46.02344,0 83.34609,-37.32266 83.34609,-83.34609z"
                      fill="#e1e1e1"
                    ></path>
                    <path
                      d="M127.21953,81.7l-58.75547,-36.11328c-1.6125,-0.97422 -3.72891,-1.04141 -5.375,-0.10078c-1.67969,0.94063 -2.72109,2.72109 -2.72109,4.63594v71.89063c0,1.91484 1.04141,3.69531 2.6875,4.63594c0.80625,0.43672 1.67969,0.67188 2.58672,0.67188c0.97422,0 1.91484,-0.26875 2.75469,-0.77266l58.75547,-35.81094c1.57891,-0.94063 2.55312,-2.6875 2.55312,-4.50156c0.06719,-1.84766 -0.90703,-3.59453 -2.48594,-4.53516z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </g>
              </svg>
              پخش ویدئو
            </button>
          </span>
        </div>
        <div v-else style="height: 305px" class="product-detail-large-img mb-2">
          <b-skeleton-img></b-skeleton-img>
        </div>
        <div
          class="swiper-container myGalleries "
          v-if="
            product != null &&
              galleries != [] &&
              !disabledSliders.includes('myGalleries')
          "
        >
          <div class="swiper-wrapper product-detail-small-img">
            <div
              v-for="(item, index) in sortedGallery"
              :key="index"
              @click="setImage(index)"
              class="p-1 ml-3 border-radius10 border swiper-slide cursor-pointer"
              :class="{
                'active-gallery':
                  (item.id !== null &&
                    product.hasVarietyAvailable != null &&
                    typeof product.hasVarietyAvailable.image !== 'string' &&
                    item.id == product.hasVarietyAvailable.image.id) ||
                  (product.hasVarietyAvailable != null &&
                    typeof product.hasVarietyAvailable.image == 'string' &&
                    item.id === null),
              }"
            >
              <img :src="item.image" alt="" />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
        <div
          v-if="product == null"
          class="product-detail-small-img d-flex justify-content-center"
        >
          <div class="p-1 ml-3 border-radius10 border">
            <b-skeleton-img style="height: 50px;"></b-skeleton-img>
          </div>
          <div class="p-1 ml-3 border-radius10 border">
            <b-skeleton-img style="height: 50px;"></b-skeleton-img>
          </div>
          <div class="p-1 ml-3 border-radius10 border">
            <b-skeleton-img style="height: 50px;"></b-skeleton-img>
          </div>
        </div>
      </div>

      <div
        class="product-detail-description d-flex flex-column text-right px-md-3 py-md-0 p-3 mx-md-3 my-3"
      >
        <div>
          <div class="d-flex justify-content-between">
            <h1
              v-if="product != null"
              class="text-color-444 weight-bold fontsize16"
            >
              {{
                beatifyTitle(
                  product.hasVarietyAvailable != null
                    ? product.hasVarietyAvailable.fullTitle
                    : product.title
                )
              }}
            </h1>
            <b-skeleton v-else class="w-100 ml-2"></b-skeleton>
            <span
              v-if="
                productVarietyAvailable == 'available' &&
                  product.hasVarietyAvailable.discountPercent != 0
              "
              class="bg-color-red text-white border-radius7 fontsize13"
              style="padding: 0px 7px;height: max-content;"
            >
              <span
                class="font-weight-bold"
                style="font-size: 14px;margin-right: 2px"
                >{{ product.hasVarietyAvailable.discountPercent }}</span
              >%</span
            >
          </div>
          <div class=" border-bottom pb-2 mb-2">
            <div class="d-flex flex-wrap">
              <div v-if="product && product.brand">
                <span class="fontsize11 weight-bold text-primary">برند :</span>
                <span class="ml-3 fontsize11 text-color-999 pr-1">{{
                  product.brand.name
                }}</span>
              </div>
              <b-skeleton
                v-else-if="!product"
                class="width-100 ml-3 my-2"
              ></b-skeleton>
              <div v-if="product && product.category">
                <span class="fontsize11 weight-bold text-primary"
                  >دسته بندی :</span
                >
                <span class="ml-3 fontsize11 text-color-999 pr-1">{{
                  product.category.name
                }}</span>
              </div>
              <b-skeleton
                v-else-if="!product"
                class="width-100 my-2"
              ></b-skeleton>
              <div class="d-flex align-items-center ">
                <template v-if="product">
                  <span class="fontsize11 weight-bold text-primary"
                    >امتیاز :</span
                  >
                  <span class=" fontsize11 text-color-999 pr-1">{{
                    product.rating
                  }}</span>
                  <button class="product-rating pt-1 pr-2">
                    <b-form-rating
                      v-model="product.rating"
                      readonly
                      variant="warning"
                      size="sm"
                      class="mb-2"
                    >
                    </b-form-rating>
                  </button>
                </template>
                <b-skeleton class="width-100 my-2 mr-2" v-else></b-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            product != null &&
              product.hasVarietyAvailable != null &&
              (product.variety == 'color-size' || product.variety == 'color')
          "
        >
          <p class="fontsize12 font-weight-bold text-color-666 mb-2 mt-2">
            انتخاب رنگ :
          </p>
          <treeselect
            v-if="hasAnyHashtagColor"
            :options="hashTagOptions"
            v-model="selectedHashTag"
            noOptionsText="موردی وجود ندارد"
            noResultsText="موردی یافت نشد"
            placeholder="انتخاب رنگ "
            :alwaysOpen="false"
            :clearable="false"
            class="mb-3"
          ></treeselect>
          <treeselect
            v-if="!hasAnyHashtagColor || selectedHashTag"
            :searchable="true"
            :options="_colorOptions"
            v-model="value"
            noChildrenText="فرزندی وجود ندارد"
            noOptionsText="موردی وجود ندارد"
            noResultsText="موردی یافت نشد"
            placeholder="انتخاب رنگ "
            :alwaysOpen="false"
            :clearable="false"
            @input="filterByColor(value)"
          >
            <label
              :style="'height: 40px'"
              slot="option-label"
              slot-scope="{ node, labelClassName }"
              :class="labelClassName"
            >
              <div
                v-if="node.raw.multi == '0'"
                class="color-option-img"
                :style="'background-color:' + node.raw.code"
              ></div>
              <div v-else-if="node.raw.multi == '1'" class="color-option-img">
                <img
                  class="w-100 h-100 color-option-img"
                  :src="node.raw.image"
                  alt=""
                />
              </div>
              {{ node.label }}
            </label>
          </treeselect>
          <!-- <div>
            <button v-for="(item, index) in initialVarieties" :key="index" class="bg-none pr-1">
              <span v-if="item.color" @click="filterByColor(item)">
                <svg :id="'tooltip-color' + item.color.id" v-if="item.color.multi == '0'"
                  :style="'background-color:' + item.color.code" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  class="border-radius10" xmlns="http://www.w3.org/2000/svg">
                  <path v-if="item.color.id == product.hasVarietyAvailable.color_id"
                    d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.62723" stroke="#fff" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span v-if="item.color.multi == '1'" class="position-relative" :id="'tooltip-color' + item.color.id">
                  <img class="border-radius10" :src="item.color.image" alt="" style="width: 24px; height: 24px;">
                  <svg class="position-absolute border-radius10 image-color" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path v-if="item.color.id == product.hasVarietyAvailable.color_id"
                      d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.62723" stroke="#000" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round" /> </svg>
                </span>
                <b-tooltip :target="'tooltip-color' + item.color.id" triggers="hover">
                  {{item.color.name}}
                </b-tooltip>
              </span>
            </button>
          </div> -->
        </div>

        <div
          v-if="
            product != null &&
              product.hasVarietyAvailable != null &&
              product.variety == 'color-size' &&
              showSize
          "
        >
          <p class="fontsize12 font-weight-bold text-color-666 mb-2 mt-2">
            انتخاب سایز :
          </p>
          <div v-if="product.variety == 'color-size'" class="btn-size">
            <button
              v-for="(item, index) in sizes"
              :key="index"
              @click="filterBySize(item)"
              :class="
                item.size.id == product.hasVarietyAvailable.size_id
                  ? 'size-active'
                  : ''
              "
              class="border bg-none  border-radius10 mr-1 px-2"
              style="min-width: 24px;"
            >
              <span class="fontsize12">{{ item.size.name }}</span>
            </button>
          </div>
        </div>
        <div
          v-if="
            product != null &&
              product.hasVarietyAvailable == null &&
              product.check_status == 'available'
          "
          class="text-center mt-2"
        >
          <span class="fontsize12 weight-bold text-color-red ml-1"
            >این محصول تنوعی ندارد</span
          >
        </div>
        <div
          v-if="
            (product != null && product.check_status == 'unavailable') ||
              productVarietyAvailable == 'unavailable'
          "
          class="text-center mb-4 mt-4"
        >
          <span class="fontsize17 weight-bold text-color-red ml-1 "
            >ناموجود</span
          >
        </div>
        <div
          v-if="
            (product != null && product.check_status == 'soon') ||
              productVarietyAvailable == 'soon'
          "
          class="text-center mb-4 mt-4"
        >
          <span class="fontsize17 weight-bold  ml-1 text-color-themeBlue"
            >به زودی</span
          >
        </div>

        <div
          v-if="
            productVarietyAvailable == 'available' && product.variety == 'title'
          "
          class="variety-title mt-4 d-flex align-items-center"
        >
          <span
            class="fontsize12 font-weight-bold text-color-666 mb-2 mt-2 ml-1 ml-sm-2"
            style="width:100px"
          >
            انتخاب عنوان :</span
          >
          <multiselect
            :allow-empty="false"
            class="text-right"
            v-model="product.hasVarietyAvailable"
            :options="varieties"
            track-by="id"
            label="fullTitle"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="انتخاب کنید..."
            @input="filterByVariety"
          >
          </multiselect>
        </div>
        <div v-if="productVarietyAvailable == 'available'" class="mt-4 mb-2">
          <span
            class="fontsize12 font-weight-bold text-color-666 mb-2 mt-4 ml-2 ml-sm-3"
            >تعداد محصول :</span
          >
          <div class="border p-1 d-inline-block border-radius10 text-color-666">
            <button
              :disabled="
                product.hasVarietyAvailable.in_storage == 0 ? true : false
              "
              @click="increment"
              type="button"
              class="py-1 px-2 border-radius10 text-color-666 bg-color-ea "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                />
              </svg>
            </button>
            <span class="fontsize11  mx-3"
              >{{
                product.hasVarietyAvailable.in_storage == 0 ? 0 : count
              }}
              عدد</span
            >
            <button
              :disabled="
                product.hasVarietyAvailable.in_storage == 0 ? true : false
              "
              @click="decrement()"
              type="button"
              class="py-1 px-2 border-radius10  bg-color-ea text-color-666"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="
            productVarietyAvailable == 'available' &&
              product.hasVarietyAvailable.in_storage != 0
          "
          class="mt-4 d-flex justify-content-between d-md-none"
        >
          <span class="fontsize12 font-weight-bold text-color-666 mb-2 ml-3">
            قیمت:</span
          >
          <div class="text-right w-75">
            <span
              v-if="product.hasVarietyAvailable.discountPercent != 0"
              class="line-through text-color-999 fontsize12 ml-3"
              ><span class="fontsize14 weight-bold ml-1">{{
                product.hasVarietyAvailable.price | price
              }}</span
              >تومان</span
            >
            <span
              v-if="product.hasVarietyAvailable.discountPercent == 0"
              class=" text-color-999 fontsize15"
              ><span class="fontsize20 weight-bold text-color-red ml-1">{{
                product.hasVarietyAvailable.price | price
              }}</span
              >تومان</span
            >
            <span v-else class=" text-color-999 fontsize15"
              ><span class="fontsize20 weight-bold text-color-red ml-1">{{
                product.hasVarietyAvailable.discountPrice | price
              }}</span
              >تومان</span
            >
          </div>
        </div>
        <div
          v-else-if="
            productVarietyAvailable == 'available' &&
              product.hasVarietyAvailable.in_storage == 0
          "
          class="text-center my-3"
        >
          <span class="fontsize12 weight-bold text-color-red ml-1"
            >موجودی این محصول به پایان رسیده است.</span
          >
        </div>
        <p
          v-if="product"
          class="text-justify fontsize12 color-black  ml-3 line-height2 pt-sm-4 pt-2"
        >
          {{ product.description }}
        </p>
        <div
          v-if="startDate"
          class="protect discountPriceTime mb-2 discountBox"
        >
          <!-- <img
            class="ml-2"
            :src="require('../../../../assets/images/prize.png')"
            alt="ضمانت"
          /> -->
          <b class="d-block text-center">شروع تخفیف از:</b>
          <flip-countdown :deadline="startDate"></flip-countdown>
        </div>
      </div>
      <PriceBox
        :productVarietyAvailable="productVarietyAvailable"
        :product="product"
        :varieties="varieties"
        :disabled="disabled"
      />

      <button
        v-if="product != null && product.hasVarietyAvailable != null"
        @click="cartToAdd()"
        :disabled="
          disabled ||
          product.check_status == 'unavailable' ||
          product.hasVarietyAvailable.in_storage == 0
            ? true
            : false
        "
        type="button"
        class="resp-cart-button d-md-none bg-color-red p-2 mx-auto border-radius15"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42631 18.9079C6.58697 18.9079 5.9075 19.6018 5.9075 20.459C5.9075 21.3061 6.58697 22 7.42631 22C8.25567 22 8.93514 21.3061 8.93514 20.459C8.93514 19.6018 8.25567 18.9079 7.42631 18.9079ZM18.6676 18.9079C17.8282 18.9079 17.1487 19.6018 17.1487 20.459C17.1487 21.3061 17.8282 22 18.6676 22C19.4969 22 20.1764 21.3061 20.1764 20.459C20.1764 19.6018 19.4969 18.9079 18.6676 18.9079Z"
            fill="#fff"
          />
        </svg>
        <span class="text-white weight-bold fontsize14"
          >افزودن به سبد خرید</span
        >
      </button>
      <button
        v-else
        :disabled="true"
        type="button"
        class="resp-cart-button d-md-none bg-color-red p-2 mx-auto border-radius15"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42631 18.9079C6.58697 18.9079 5.9075 19.6018 5.9075 20.459C5.9075 21.3061 6.58697 22 7.42631 22C8.25567 22 8.93514 21.3061 8.93514 20.459C8.93514 19.6018 8.25567 18.9079 7.42631 18.9079ZM18.6676 18.9079C17.8282 18.9079 17.1487 19.6018 17.1487 20.459C17.1487 21.3061 17.8282 22 18.6676 22C19.4969 22 20.1764 21.3061 20.1764 20.459C20.1764 19.6018 19.4969 18.9079 18.6676 18.9079Z"
            fill="#fff"
          />
        </svg>
        <span class="text-white weight-bold fontsize14"
          >افزودن به سبد خرید</span
        >
      </button>
    </section>

    <section class="product-specifications-comments  text-right  mb-5">
      <ul class="nav container nav-tabs border-bottom-0 bg-color-fa py-4 px-1">
        <li
          style="min-height: 30px"
          v-if="product != null && product.body != null"
          :class="activeTab === 'review' ? 'active' : ''"
        >
          <a
            class="p-sm-3 p-2 m-1 border-radius10 text-color-444 fontsize14 font-weight-bold"
            :class="activeTab === 'review' ? 'active' : ''"
            data-toggle="tab"
            @click="changeTab('review')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.3304 2.0004H16.6694C20.0704 2.0004 21.9904 3.9294 22.0004 7.3304V16.6704C22.0004 20.0704 20.0704 22.0004 16.6694 22.0004H7.3304C3.9294 22.0004 2.0004 20.0704 2.0004 16.6704V7.3304C2.0004 3.9294 3.9294 2.0004 7.3304 2.0004ZM12.0494 17.8604C12.4804 17.8604 12.8394 17.5404 12.8794 17.1104V6.9204C12.9194 6.6104 12.7704 6.2994 12.5004 6.1304C12.2194 5.9604 11.8794 5.9604 11.6104 6.1304C11.3394 6.2994 11.1904 6.6104 11.2194 6.9204V17.1104C11.2704 17.5404 11.6294 17.8604 12.0494 17.8604ZM16.6504 17.8604C17.0704 17.8604 17.4294 17.5404 17.4804 17.1104V13.8304C17.5094 13.5094 17.3604 13.2104 17.0894 13.0404C16.8204 12.8704 16.4804 12.8704 16.2004 13.0404C15.9294 13.2104 15.7804 13.5094 15.8204 13.8304V17.1104C15.8604 17.5404 16.2194 17.8604 16.6504 17.8604ZM8.2194 17.1104C8.1794 17.5404 7.8204 17.8604 7.3894 17.8604C6.9594 17.8604 6.5994 17.5404 6.5604 17.1104V10.2004C6.5304 9.8894 6.6794 9.5804 6.9504 9.4104C7.2194 9.2404 7.5604 9.2404 7.8304 9.4104C8.0994 9.5804 8.2504 9.8894 8.2194 10.2004V17.1104Z"
                fill="#444"
              />
            </svg>
            نقد و بررسی
          </a>
        </li>
        <li
          v-if="product != null && attributes != ''"
          :class="activeTab === 'specifications' ? 'active' : ''"
        >
          <a
            :class="activeTab === 'specifications' ? 'active' : ''"
            class="p-sm-3 p-2 m-1 border-radius10 text-color-444 fontsize14 font-weight-bold"
            data-toggle="tab"
            @click="changeTab('specifications')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.67018 1.99991H16.3402C19.7302 1.99991 22.0002 4.37991 22.0002 7.91991V16.0899C22.0002 19.6199 19.7302 21.9999 16.3402 21.9999H7.67018C4.28018 21.9999 2.00018 19.6199 2.00018 16.0899V7.91991C2.00018 4.37991 4.28018 1.99991 7.67018 1.99991ZM7.52018 13.1999C6.86018 13.1999 6.32018 12.6599 6.32018 11.9999C6.32018 11.3399 6.86018 10.8009 7.52018 10.8009C8.18018 10.8009 8.72018 11.3399 8.72018 11.9999C8.72018 12.6599 8.18018 13.1999 7.52018 13.1999ZM10.8002 11.9999C10.8002 12.6599 11.3402 13.1999 12.0002 13.1999C12.6602 13.1999 13.2002 12.6599 13.2002 11.9999C13.2002 11.3399 12.6602 10.8009 12.0002 10.8009C11.3402 10.8009 10.8002 11.3399 10.8002 11.9999ZM15.2802 11.9999C15.2802 12.6599 15.8202 13.1999 16.4802 13.1999C17.1402 13.1999 17.6702 12.6599 17.6702 11.9999C17.6702 11.3399 17.1402 10.8009 16.4802 10.8009C15.8202 10.8009 15.2802 11.3399 15.2802 11.9999Z"
                fill="#444"
              />
            </svg>
            مشخصات
          </a>
        </li>
        <li :class="activeTab === 'product-comments' ? 'active' : ''">
          <a
            :class="activeTab === 'product-comments' ? 'active' : ''"
            class="p-sm-3 p-2 m-1 border-radius10 text-color-444 fontsize14 font-weight-bold"
            data-toggle="tab"
            @click="changeTab('product-comments')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z"
                fill="#444"
              />
            </svg>
            نظرات کاربران
          </a>
        </li>
      </ul>

      <div
        class="tab-content position-relative container border-radius15 box-shaddow20 pb-4"
      >
        <div id="review">
          <div class="d-flex">
            <div style="flex: 1" class="px-sm-3 px-1">
              <template v-if="product">
                <div v-if="product.body">
                  <h4 class="mt-4 pr-2">نقد و بررسی {{ product.title }}</h4>
                  <p
                    class="fontsize14  text-justify line-height2 p-3 mb-sm-3 mb-1"
                    style="line-height:2.5"
                    v-html="product.body"
                  ></p>
                </div>
              </template>
              <span v-else class="p-3">
                <b-skeleton class="w-100 ml-2"></b-skeleton>
                <b-skeleton class="w-100 ml-2"></b-skeleton>
                <b-skeleton class="w-100 ml-2"></b-skeleton>
                <b-skeleton class="w-100 ml-2"></b-skeleton>
              </span>
              <!--        SPECIFICATIONS      -->
              <div
                id="specifications"
                v-if="attributes != '' && product.attributes_active != ''"
                class="specifications-grid  pb-3 pt-0"
              >
                <h4 class="mt-1 pr-2">مشخصات</h4>

                <div
                  v-for="attribute in attributes"
                  :key="attribute.id"
                  class="d-flex"
                  style="gap: 10px"
                >
                  <div
                    class="attribute-key border-radius7 mb-2 py-2 px-sm-4 px-2 bg-color-f7f9f9 fontsize13 font-weight-bold text-color-444"
                    style="background-color: #f7f6ff;"
                  >
                    {{ attribute.name }}
                  </div>
                  <div
                    style="flex: 8"
                    class="border-radius7 mb-2 py-2 px-sm-4 px-2 bg-color-f7f9f9 fontsize13 text-color-444"
                  >
                    <span
                      v-for="(attributeItem,
                      index) in product.attributes_active"
                      :key="index"
                    >
                      <span
                        v-if="
                          attributeItem.id == attribute.id &&
                            attributeItem.pivot
                        "
                      >
                        <span v-if="attributeItem.type == 'multi_select'">
                          {{ attributeItem.pivot.value }}
                          {{
                            index + 1 == product.attributes_active.length
                              ? ""
                              : " - "
                          }}
                        </span>
                        <span v-else style="white-space: pre-wrap">
                          {{ attributeItem.pivot.value }}</span
                        >
                      </span>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
              <!-- COMMENTS -->
              <productComments
                v-if="product"
                :comments="product != null ? product.comments_actvie : ''"
                :productId="product != null ? product.id : 0"
              />
            </div>
            <div class="d-none d-md-block">
              <ProductStickyButton
                :product="product"
                :disabled="disabled"
                :product-variety-available="productVarietyAvailable"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
    <section
      v-if="!product || (products && products.length !== 0)"
      class="same-products container"
    >
      <div class="d-flex justify-content-between mb-4">
        <div class="mt-1">
          <img src="../../assets/images/circle.png" alt="" />
          <!-- <div class="circle1 rounded-circle mt-1 d-inline-block"></div> -->
          <h5 class="d-inline-block">
            <span class="weight-bold text-color-444">محصولات</span>
            <span class="text-color-themeBlue fontsize14 font-weight-bolder">
              مشابه</span
            >
          </h5>
        </div>
        <div class="line mx-2 mt-3"></div>
        <div class="d-flex mt-1">
          <button
            class="position-relative arrow-circle  rounded-circle d-block"
          >
            <div class="swiper-button-next"></div>
          </button>
          <button
            class="position-relative arrow-circle   rounded-circle d-block mr-2"
          >
            <div class="swiper-button-prev"></div>
          </button>
        </div>
      </div>

      <div
        v-if="!disabledSliders.includes('sameProducts')"
        class="swiper-container py-3 mb-4"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in products"
            :key="index"
            class="swiper-slide discounted-products-item  box-shaddow20 border-radius15"
          >
            <product :product="item" />
          </div>
        </div>
      </div>
      <router-link
        to="/products"
        class="bg-color-themeBlue rounded-pill py-2 px-4 fontsize12 font-weight-bold text-white d-table mx-auto"
        >محصولات بیشتر
      </router-link>
    </section>
    <b-modal
      id="gallery-modal"
      hide-footer
      class="border-0"
      size="lg"
      :title="' تصاویر ' + (product != null ? product.title : '')"
    >
      <section
        v-if="product != null"
        class="product-details-img d-flex border-lg-left justify-content-center mb-lg-0 mb-4"
      >
        <div class="product-details-l-img border-radius10 ">
          <!-- <img v-if="product.hasVarietyAvailable != null" :src="product.hasVarietyAvailable.image.image" alt=""
            style="height: 400px;"> -->
          <inner-image-zoom
            class="d-none d-md-block"
            v-if="!hasVideo"
            :src="image"
            :zoomScale="2"
            :zoomSrc="image"
          />
          <video-player
            v-else-if="hasVideo"
            class="video-player-box"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
          >
          </video-player>
        </div>
        <div class="product-details-s-img ml-2 d-none d-md-block">
          <div v-if="sortedGallery != null" class="d-flex flex-wrap ">
            <div
              v-for="(item, index) in sortedGallery"
              :key="'galleries' + index"
              @click="setImage(index)"
              :class="{ 'active-gallery': activeImageIndex == index }"
              class="mr-1"
            >
              <img :src="item.image" alt="" />
            </div>
            <div
              v-if="product.video != null"
              @click="setVideo()"
              :class="hasVideo ? 'active-gallery' : ''"
              class="mr-1 img-video"
            >
              <span class="position-absolute v-play-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 172 172"
                  style=" fill:#000000;"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g>
                      <path
                        d="M169.34609,86c0,-46.02344 -37.32266,-83.34609 -83.34609,-83.34609c-46.02344,0 -83.34609,37.32266 -83.34609,83.34609c0,46.02344 37.32266,83.34609 83.34609,83.34609c46.02344,0 83.34609,-37.32266 83.34609,-83.34609z"
                        fill="#fff"
                      ></path>
                      <path
                        d="M127.21953,81.7l-58.75547,-36.11328c-1.6125,-0.97422 -3.72891,-1.04141 -5.375,-0.10078c-1.67969,0.94063 -2.72109,2.72109 -2.72109,4.63594v71.89063c0,1.91484 1.04141,3.69531 2.6875,4.63594c0.80625,0.43672 1.67969,0.67188 2.58672,0.67188c0.97422,0 1.91484,-0.26875 2.75469,-0.77266l58.75547,-35.81094c1.57891,-0.94063 2.55312,-2.6875 2.55312,-4.50156c0.06719,-1.84766 -0.90703,-3.59453 -2.48594,-4.53516z"
                        fill="#999"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <img :src="product.image" alt="" style="opacity: 0.5;" />
            </div>
          </div>
        </div>
      </section>
    </b-modal>
  </main>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";
import product from "./component/product";
import productComments from "./component/product/productComments";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import { videoPlayer } from "vue-video-player";
import FlipCountdown from "vue2-flip-countdown";

import "video.js/dist/video-js.css";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ProductStickyButton from "./component/product-detail/ProductStickyButton";
import PriceBox from "./component/product-detail/PriceBox";
export default {
  components: {
    PriceBox,
    ProductStickyButton,
    product,
    productComments,
    InnerImageZoom,
    videoPlayer,
    Treeselect,
    FlipCountdown,
  },
  data() {
    return {
      selectedHashTag: null,
      products: Array(6).fill(null),
      disabledSliders: [],
      product: null,
      attributes: [],
      image: "",
      varieties: [],
      initialVarieties: [],
      variety: "",
      count: 1,
      disabledFavorite: false,
      showSize: true,
      title: "محصول",
      sizes: [],
      galleries: [],
      disabled: false,
      productVarietyAvailable: "",
      activeTab: null,
      selectedImage: null,
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
      },
      hasVideo: false,
      activeImageIndex: null,
      value: null,
      colorOptions: [],
    };
  },
  computed: {
    _colorOptions() {
      let options = [];
      this.colorOptions.forEach((_cO) => {
        let cO = { ..._cO };
        if (cO.label.includes("#")) {
          let tag = cO.label.split("#")[0];
          if (this.selectedHashTag && this.selectedHashTag == tag) {
            cO.label = cO.label.split("#")[1];
            options.push(cO);
          }
        }
      });
      console.log(options);
      return options;
    },
    hashTagOptions() {
      let options = [];
      this.colorOptions.forEach((cO) => {
        if (cO.label.includes("#")) {
          let l = cO.label.split("#")[0];
          if (!options.find((opt) => opt.label == l)) {
            options.push({
              id: l,
              label: l,
            });
          }
        }
      });
      return options;
    },
    hasAnyHashtagColor() {
      let yes = false;
      this.colorOptions.forEach((cO) => {
        if (cO.label.includes("#")) {
          yes = true;
        }
      });
      return yes;
    },
    hierarchyCategories() {
      if (!this.product) {
        return [];
      }
      if (!this.product.category.all_parent) {
        return [this.product.category];
      }
      let parents = this.loadCategoryParent(this.product.category, [
        this.product.category,
      ]);

      return parents.reverse();
    },
    sortedGallery() {
      return [...this.galleries].sort((item1, item2) => {
        return (
          (this.product.hasVarietyAvailable != null &&
          item1.variety_id == this.product.hasVarietyAvailable.id
            ? 0
            : 1) -
          (this.product.hasVarietyAvailable != null &&
          item2.variety_id == this.product.hasVarietyAvailable.id
            ? 0
            : 1)
        );
      });
    },
    startDate() {
      if (!this.varieties && !this.varieties.length) {
        return false;
      }
      let selectedDiscount = null;
      this.varieties.forEach((v) => {
        if (v.discounts) {
          v.discounts.forEach((d) => {
            selectedDiscount = d;
          });
        }
      });
      if (!selectedDiscount) {
        return false;
      }
      let now = new Date();
      let discountTime = new Date(selectedDiscount.start_date);


      if (now.getTime() > discountTime.getTime()) {
        return false;
      } else {
        return window
          .moment(selectedDiscount.start_date)
          .format("YYYY/MM/DD HH:mm");
      }
    },
  },
  mounted() {
    this.deleteOldMeta();
    this.initProducts();
    this.$route.params.id ? this.loadProduct() : "";
    document.title = this.title;
  },
  methods: {
    deleteOldMeta() {
      document.querySelector('meta[name="description"]').remove();
    },
    beatifyTitle(title) {
      return title.replace("#", " ");
    },
    loadCategoryParent(cat, parents = []) {
      let parent = cat.all_parent;
      if (parent) {
        parents.push(parent);
        parents = this.loadCategoryParent(parent, parents);
      }
      return parents;
    },
    cartToAdd() {
      if (this.$store.state.header.user) {
        this.disabled = true;
        this.$axios
          .post(`/api/cart/add/${this.product.hasVarietyAvailable.id}`, {
            number: this.count,
            unit: this.product.unit,
          })
          .then((response) => {
            this.$root.success_notification(response.data.message);
            this.$store.commit("header/pushCart", response.data.data);
            this.$router.push("/cart");
          })
          .catch((error) => {
            console.log(error);
            this.$root.error_notification(error);
          })
          .finally(() => {
            this.disabled = false;
          });
      } else {
        this.$("#loginModal").modal("show");
        this.$store.commit("setPendingCartToAdd", {
          id: this.product.hasVarietyAvailable.id,
          number: this.count,
          unit: this.product.unit,
        });
      }
    },
    loadProduct() {
      this.$axios
        .get("/api/products/" + this.$route.params.id)
        .then((response) => {
          let product = response.data.data;
          this.product = product.product;
          this.variety =
            product.product.hasVarietyAvailable != null
              ? product.product.hasVarietyAvailable
              : "";
          // let allAttrs = window.clone(product.attributes);
          let notFound = [];
          this.product.attributes.forEach((pa) => {
            let finded = product.attributes.find((a) => pa.id == a.id);
            if (!finded) notFound.push(pa);
          });
          product.attributes.push(...notFound);
          this.attributes = product.attributes;
          this.products = product.products;
          this.galleries = product.galleries;

          this.galleries.unshift({
            image: product.product.image,
            variety_id: null,
          });
          this.galleries != [] ? this.initGalleries(true) : "";
          if (
            this.product.variety == "color-size" ||
            (this.product.variety == "color" &&
              this.product.hasVarietyAvailable != null)
          ) {
            let res = Object.values(
              product.varieties.reduce(
                (acc, obj) => ((acc[obj.color_id] = obj), acc),
                {}
              )
            );
            this.initialVarieties = res;
            this.value = this.product.hasVarietyAvailable.color_id;
            let colors = [];
            this.initialVarieties.map((c) => {
              colors.push({
                id: c.color.id,
                label: c.color.name,
                code: c.color.code,
                image: c.color.image,
                multi: c.color.multi,
                variety: c,
              });
            });

            this.colorOptions = colors;

            this.varieties = product.varieties;
            this.sizes = this.varieties.filter(
              (el) => el.color_id == this.product.hasVarietyAvailable.color_id
            );
          } else {
            this.varieties = product.varieties;
          }
          if (this.product.hasVarietyAvailable != null) {
            this.image = this.product.hasVarietyAvailable.image;
          } else {
            this.image = this.product.image;
          }
          this.title = this.product.title;
          document.title = `خرید ${this.title}`;
          this.productVarietyStatus();
          if (this.product.video != null) {
            this.playerOptions.sources[0].src = this.product.video;
            //  this.playerOptions.poster = this.product.image
          }
          this.initProducts(true);

          this.activeTab =
            this.product.body != null
              ? "review"
              : this.product.body == null && this.attributes != ""
              ? "specifications"
              : "comments";
          // age payinim biar bala
          if (document.documentElement.scrollTop > 50) {
            this.$root.scrollTo("main");
          }
        })
        .catch(() => {});
    },
    filterByVariety(event) {
      if (event && event.id != this.product.hasVarietyAvailable.id) {
        this.count = 1;
        this.product.hasVarietyAvailable = event;
        event.image ? (this.image = event.image) : "";
        this.productVarietyStatus();
      }
    },
    productVarietyStatus() {
      if (
        this.product.check_status == "available" &&
        this.product.hasVarietyAvailable != null &&
        this.product.hasVarietyAvailable.status == "available"
      ) {
        this.productVarietyAvailable = "available";
      } else if (
        this.product.check_status == "available" &&
        this.product.hasVarietyAvailable != null &&
        this.product.hasVarietyAvailable.status == "soon"
      ) {
        this.productVarietyAvailable = "soon";
      } else if (
        this.product.check_status == "available" &&
        this.product.hasVarietyAvailable != null &&
        this.product.hasVarietyAvailable.status == "unavailable"
      ) {
        this.productVarietyAvailable = "unavailable";
      }
    },
    filterByColor(value) {
      let color = this.colorOptions.filter((item) => item.id == value)[0];

      this.count = 1;
      this.product.variety == "color-size"
        ? (this.sizes = this.varieties.filter((el) => el.color_id == value))
        : "";
      this.product.hasVarietyAvailable = color.variety;
      color.variety.image ? (this.image = color.variety.image) : "";
      this.productVarietyStatus();
    },
    filterBySize(item) {
      this.count = 1;
      this.product.hasVarietyAvailable = item;
      item.image ? (this.image = item.image) : "";
      this.productVarietyStatus();
    },
    favorite() {
      this.disabledFavorite = true;
      if (this.$store.state.header.user) {
        this.$axios
          .post("/api/favorites/add/" + this.product.id)
          .then((response) => {
            this.product.favorite == false
              ? (this.product.favorite = true)
              : (this.product.favorite = false);
            this.$root.success_notification(response.data.message);
          })
          .catch((error) => {
            this.$root.error_notification(error);
          })
          .finally(() => {
            this.disabledFavorite = false;
          });
      } else {
        this.$root.warning_notification("ابتدا وارد حساب کاربری خود شوید");
        this.disabledFavorite = false;
        this.show = false;
      }
    },
    setImage(index) {
      this.activeImageIndex = index;
      this.image = this.sortedGallery[index].image;
      this.hasVideo = false;
      let bodyWidth = document.body.offsetWidth;
      if (bodyWidth > 766) {
        this.$root.$emit("bv::show::modal", "gallery-modal");
      }
    },
    setVideo() {
      this.hasVideo = true;
      this.$root.$emit("bv::show::modal", "gallery-modal");
    },
    initProducts(kill = false) {
      this.initSwiper("sameProducts", kill, () => {
        new Swiper(".same-products .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 800,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".same-products .swiper-button-next",
            prevEl: ".same-products .swiper-button-prev",
          },
          breakpoints: {
            550: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    initGalleries(kill = false) {
      this.initSwiper("myGalleries", kill, () => {
        new Swiper(".myGalleries", {
          spaceBetween: 30,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            400: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    decrement() {
      this.count > 1 ? (this.count = this.count - 1) : "";
    },
    increment() {
      this.count < this.product.hasVarietyAvailable.in_storage
        ? (this.count = this.count + 1)
        : "";
    },
    changeTab(name) {
      document.getElementById(name).scrollIntoView({
        behavior: "smooth",
      });
      this.activeTab = name;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.product
            ? this.product.title +
              (this.product.description ? " - " + this.product.description : "")
            : "محصول",
        },
        {
          property: "og:title",
          content: this.product ? this.product.title : "محصول",
        },
        {
          property: "title",
          content: this.product ? this.product.title : "محصول",
        },
      ],
    };
  },
};
</script>
<style scoped>
.btn-size .bg-none:hover {
  background: var(--color-themeBlue-l) !important;
}

.btn-size .size-active.bg-none {
  border-color: var(--color-themeBlue-i) !important;
  background: var(--color-themeBlue-l) !important;
}

.image-color {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.color-option-img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
</style>
<style>
.in-product-detail .vue-treeselect__label-container {
  display: block;
}
.in-product-detail .vue-treeselect {
  text-align: right;
  width: 220px;
}
.in-product-detail .vue-treeselect__control {
  border-radius: 10px;
}
.in-product-detail .vue-treeselect__single-value {
  font-size: 12px !important;
  color: #666;
}
.in-product-detail .vue-treeselect--single .vue-treeselect__option--selected {
  background: #7570b338;
}
.in-product-detail .same-products .discounted-products-img img {
  height: 165px;
  width: 165px;
}

.in-product-detail .swiper-button-prev::after,
.in-product-detail .swiper-button-next::after {
  font-size: 15px;
}

.in-product-detail .discounted-products-img {
  height: 160px !important;
  width: 100%;
}

/* .in-product-detail .swiper-button-prev,
  .in-product-detail .swiper-button-next {
    top: 38%;
  } */

/* .in-product-detail .swiper-button-prev ,.in-product-detail .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
  } */
.in-product-detail .swiper-container.myGalleries {
  height: 60px !important;
}

.product-detail-small-img .b-aspect-content {
  height: 50px !important;
}

.product-rating .form-control {
  height: unset;
  border: unset;
  padding: unset;
}

.product-rating .b-rating .b-rating-star {
  padding: 0 1px;
}

.product-rating .form-control:focus {
  box-shadow: unset;
}

.product-rating .b-icon.bi {
  width: 13px;
  height: 13px;
}

.variety-title .multiselect__tags {
  border-radius: 10px;
  font-size: 12px;
}

.variety-title .multiselect {
  min-width: 150px;
}

@media screen and (max-width: 400px) {
  .product-detail-small-img .swiper-slide {
    width: 60px !important;
  }

  .in-product-detail .swiper-container {
    padding: 0 10px;
  }

  .in-product-detail .same-products .discounted-products-img img {
    height: 208px;
    width: 208px;
  }

  .in-product-detail .discounted-products-img {
    height: 200px !important;
  }
}

.in-product-detail .product-detail-small-img > div {
  width: 60px !important;
}
</style>
<style scoped>
.active-gallery {
  border: 1px solid !important;
}

main.in-product-detail {
  padding-top: 0 !important;
}

@media screen and (min-width: 767px) {
  main.in-product-detail {
    padding-top: 10px !important;
  }
}

.in-product-detail .width-70 {
  width: 70px;
}

.in-product-detail .width-100 {
  width: 100px;
}

.in-product-detail .width-130 {
  width: 130px;
}

.product-details-s-img {
  height: 400px;
  overflow-y: auto;
  padding-left: 10px;
  max-width: 40%;
  scrollbar-color: var(--color-themeBlue) var(--color-themeBlue-l);
  scrollbar-width: thin;
}

/* .product-details-s-img>div {
    min-height: 350px;
  } */

.product-details-s-img a img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.product-details-s-img a {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  flex-shrink: 0;
  border: 1px solid #f1f1f1;
}

.product-details-l-img {
  width: 400px;
  height: 400px;
  max-width: 400px;
  max-height: 400px;
  overflow: hidden;
  text-align: center;
  /*padding-left: 15px;*/
}

.product-details-l-img img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.prodcuct-video {
  box-shadow: 0px 0px 10px #f5f5f5;
  padding: 5px 8px;
  font-size: 12px;
  border-radius: 10px;
  margin-right: 5px;
  color: #666;
}

.favorite-btn {
  box-shadow: 0px 0px 10px #f5f5f5;
  padding: 5px 8px;
}

.in-product-detail >>> .multiselect__option--highlight {
  background: #7570b3;
}
.cat-breadcrump {
  display: contents;
  /*transform: translateY(-4px);*/
}
</style>
<style>
.in-product-detail .b-aspect.d-flex {
  height: 100%;
}

@media screen and (max-width: 992px) {
  #gallery-modal .modal-lg {
    max-width: 90vw;
  }
}

#gallery-modal .modal-title {
  font-size: 15px;
}

#gallery-modal .modal-header .close {
  margin: -1rem 0 -1rem 0;
}
#gallery-modal .modal-content {
  border-radius: 10px;
  border: unset;
}
.video-js {
  width: 400px;
  height: 400px;
}

.vjs-poster {
  opacity: 0.6;
}

.video-js .vjs-big-play-button {
  top: 44%;
  left: 40%;
}

.img-video {
  background-color: #000;
  position: relative;
}

.img-video .v-play-icon {
  left: 40%;
  top: 40%;
}

.video-js .vjs-loading-spinner {
  border: none;
}

.video-js .vjs-loading-spinner:before,
.video-js .vjs-loading-spinner:after {
  left: 40%;
  border: 6px solid rgba(43, 51, 63, 0.7);
}
.color-black {
  color: black;
}
@media screen and (max-width: 766px) {
  .product-details-l-img {
    width: 100% !important;
    max-width: 100% !important;
  }
  .video-js {
    width: 100% !important;
  }
  #gallery-modal .modal-title {
    display: none;
  }
  .tab-content {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.discountBox {
  display: none;
}
@media screen and (max-width: 566px) {
  .video-js,
  .product-details-l-img {
    height: 300px !important;
  }
  .discountBox {
    display: block;
  }
}
@media screen and (max-width: 366px) {
  .video-js,
  .product-details-l-img {
    height: 250px !important;
  }
}
</style>
